.reward_center{
    width: 100%;
    height: 90vh;
    margin: 0;
    background: #941A22;
}

.number_of_stamp_style{
    position: fixed;
    bottom: 11.466vw;
    left: 0;
    right: 0;
    width: 85%; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 5px; 
    background: #175336; 
    color: #ffffff; 
    font-size: 14px; 
    font-weight: 700; 
    text-align: center; 
    padding: 4% 0; 
    border-radius: 7px;
    z-index: 998;
}

.header_con{
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-bottom: 20px; 
    display: flex; 
    width: 100%; 
    background: none; 
    z-index: 999;
    align-items: flex-start;
}

.back_btn_con{
    text-align: left; 
    margin-left: 10px;
    margin-top: 12px;
}

.back_btn{
    width: 32px; 
    height: 32px;
}

.header_title_img{
    width: 100%;
}

.reward_bg{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.reward_light_top{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100%;
    margin-top: 55%;
    z-index: 995;
    pointer-events: none;
}

.reward_light_bottom{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 100%;
    margin-top: 126%;
    z-index: 998;
    pointer-events: none;
}

.login_con_wrap{
    width: 100%;
    position: relative; 
    margin-left: auto; 
    margin-right: auto; 
    text-align: center; 
    padding-top: 23%;
}

.login_popup_con{
    position: relative; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 5px;
}

.login_popup_bg{
    width: 100%; 
    margin-left: auto; 
    margin-right: auto;
}

.login_popup_text_con{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 90%; 
    text-align: center;
}

.login_popup_title{
    font-size: 26px; 
    color: #D13740; 
    mix-blend-mode: normal; 
    -webkit-text-stroke: 0.2px #FFFFFF; 
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25); 
    margin-bottom: 2%; 
    margin-top: 6%;
}

.login_popup_content{
    font-size: 14px; 
    color: #FFFFFF; 
    width: 90%; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 0; 
    margin-bottom: 0;
}

.login_popup_btn{
    width: 80%; 
    padding: 2.5% 0px; 
    font-size: 16px; 
    color: #ffffff; 
    font-weight: 700; 
    background: linear-gradient(180deg, #59A64C 0%, #2E8620 100%); 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.5), inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 2px rgba(14, 100, 43, 0.6); 
    border-radius: 30px; 
    border: 0px solid; 
    margin-top: 3%;
}

.reward_info_wrap{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 82%; 
    text-align: left; 
    margin-top: 5.5%;
}   

.reward_info_top_con{
    display: flex;
}

.reward_stats_con{
    width: 38%;
}

.reward_stats_title{
    margin: 0; 
    color: #ffffff; 
    font-size: 14px; 
    font-weight: 700; 
    margin-bottom: 5px;
    padding-left: 2vw;
}

.reward_stats_progress{
    display: flex; 
    width: 100%; 
    text-align: left; 
    align-items: baseline;
    padding-left: 2vw;
}

.reward_stats_done{
    margin: 0; 
    color: #ffffff; 
    font-size: 60px; 
    font-weight: 700; 
    width: 30%;
    display: flex;
    >img{
        height: 12vw;
        padding-top: 2.6667vw;
    }
}

.reward_stats_remain{
    margin: 0; 
    color: #ffffff; 
    font-size: 24px; 
    font-weight: 700; 
    width: 70%;
}

.reward_stats_divider{
    margin-right: 10px; 
    font-size: 28px;
}

.reward_stamp_wrap{
    display: flex; 
    width: 62%; 
    margin-left: auto; 
    margin-right: auto; 
    flex-flow: row wrap; 
    //justify-content: space-between; 
    align-items: flex-start;
}

.reward_stamp_con{
    width: 25%;
}

.reward_stamp_icon{
    width: 100%;
    height: auto;
}

.reward_info_bottom_con{
    background: #ffffff; 
    border: 0px solid; 
    border-radius: 50px; 
    width: 100%; 
    display: flex; 
    align-items: center; 
    margin-left: auto; 
    margin-right: auto; 
    padding: 3% 0px;
}

.reward_info_btn_space{
    width: 20%;
}

.reward_info_btn_con{
    width: 65%; 
    color: #B61F28; 
    font-weight: 700; 
    text-align: left;
    font-size: 14px;
}

.reward_info_btn_text{
    width: 75%; 
    margin-left: auto; 
    margin-right: auto;
    white-space: nowrap;
}

.reward_info_btn_icon{
    width: 15%;
}

.reward_info_btn_arrow{
    width: 60%;
}

.reward_info_btn_char{
    position: absolute; 
    bottom: 0; 
    left: 0; 
    margin-bottom: -5%;
}

.reward_info_btn_img{
    width: 45%;
    pointer-events: none;
}

.reward_coupon_wrap{
    position: relative; 
    width: 100%; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 8%; 
    z-index: 996;
}

.reward_coupon_con{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    text-align: center;
}

.coupon_reward{
    z-index: 999 !important;
}

.coupon_claimed{
    z-index: 999 !important;
}
.hide_under{
    z-index: 0 !important;
}

.reward_coupon_bg{
    width: 96%; 
    margin-top: -5%;
} 

.reward_coupon_title_con{
    position: absolute; 
    width: 90%; 
    top:0; 
    left: 0; 
    right: 0; 
    margin-right: auto; 
    margin-left: auto; 
    color: #B61F28; 
    font-size: 24px; 
    font-weight: 700; 
    -webkit-text-stroke: 0.25px #F1F1F1; 
    -webkit-text-fill-color: #B61F28;
    margin-top: -3%; 
    display: flex; 
    text-align: center; 
    align-self: center; 
    justify-content: center;

    .titleLeftImg{
        width: 14.133vw;
    }

    .titleRightImg{
        width: 20.533vw;
    }
}  

.couponNum{
    display: flex;
    margin-left: 10px;
    >img{
        width: 5vw;
    }
}

.reward_coupon_title_left{
    width: 50%;
}

.reward_coupon_title_right{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reward_coupon_title_full{
    width: 100%;
}

.reward_coupon_inner_bg{
    position: absolute; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    margin-right: auto; 
    margin-left: auto; 
    background: #286648; 
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25); 
    width: 85%; 
    height: 80%; 
    border-radius: 30px; 
    margin-bottom: 5%;
}

.reward_coupon_inner_con{
    position: relative; 
    display: flex; 
    width: 90%; 
    height: 100%; 
    margin-right: auto; 
    margin-left: auto; 
    align-self: center; 
    justify-content: center;
}

.coupon_inner_con{
    width: 50%; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    position: relative;
}

.coupon_img{
    width: 100%; 
    position: relative;
}

.coupon_inner_btn{
    background: #ffffff; 
    position: absolute; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-bottom: 28%; 
    border: 0px solid; 
    border-radius: 50px; 
    width: 80%; 
    font-size: 12px; 
    font-weight: 600; 
    color: #000000; 
    padding: 7%;
}

.coupon_inner_btn_wrap{
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.coupon_claim_icon{
    width: 55%; 
    margin: 0 5px;
}


/* Galaxy Fold */
@media only screen and (min-device-width: 250px) and (max-device-width: 280px) {
    .deco_light_top{
        top: 340px;
    }

    .reward_stats_title{
        font-size: 14px;
    }

    .reward_stats_divider{
        margin-left: 10px;
    }

    .reward_info_btn_img{
        width: 40%;
    }

    .reward_info_btn_con{
        font-size: 12px;
    }

    .reward_stats_done{
        font-size: 50px;
    }

    .reward_coupon_title_con{
        font-size: 18px;
    }

    .coupon_inner_btn{
        font-size: 10px;
    }

    .coupon_claim_icon{
        width: 30%;
    }
}

/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
    .reward_stats_title{
        font-size: 14px;
    }

    .reward_stats_divider{
        margin-left: 10px;
    }

    .reward_stats_title{
        margin-bottom: 0px;
    }

    .reward_info_btn_img{
        width: 40%;
    }

    .reward_coupon_title_con{
        font-size: 18px;
    }

    .coupon_inner_btn{
        font-size: 10px;
    }

    .coupon_claim_icon{
        width: 30%;
    }
}

/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 359px) {
    .reward_info_btn_con{
        font-size: 12px;
    }
}
@media only screen and (min-device-width: 375px) and (min-device-height: 700px) {
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
    .reward_center{
        width: 100%;
    }
}
/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {
    .reward_center{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .reward_bg{
        width: 450px;
    }

    .header_con{
        width: 450px;
    }

    .reward_light_top{
        width: 450px;
        margin-top: 250px;
    }

    .reward_light_bottom{
        width: 450px;
        margin-top: 575px;
    }

    .login_con_wrap{
        width: 450px;
        padding-top: 105px;
    }

}

/* Media Queries */
@media screen and (max-width: 600px) {

}

