@import "theme";
.page{
    
    min-height: 100vh;

    &[class*=_0]{
        background:#DE7D0C repeating-linear-gradient(-45deg, transparent, transparent 12px,
        rgba(255, 255, 255, 0.07) 12px, 
        rgba(255, 255, 255, 0.07) 24px);
    }

    &[class*=_1]{
        background:#8BC6C0 repeating-linear-gradient(-45deg, transparent, transparent 12px,
        rgba(255, 255, 255, 0.07) 12px, 
        rgba(255, 255, 255, 0.07) 24px);
    }

    &[class*=_2]{
        background:#9E7340 repeating-linear-gradient(-45deg, transparent, transparent 12px,
        rgba(255, 255, 255, 0.07) 12px, 
        rgba(255, 255, 255, 0.07) 24px);
    }

    &[class*=_3]{
        background:#3E2567 repeating-linear-gradient(-45deg, transparent, transparent 12px,
        rgba(255, 255, 255, 0.07) 12px, 
        rgba(255, 255, 255, 0.07) 24px);
    }

    &[class*=_4]{
        background:#F3A9C4 repeating-linear-gradient(-45deg, transparent, transparent 12px,
        rgba(255, 255, 255, 0.07) 12px, 
        rgba(255, 255, 255, 0.07) 24px);
    }

    &[class*=_5]{
        background:#FFA461 repeating-linear-gradient(-45deg, transparent, transparent 12px,
        rgba(255, 255, 255, 0.07) 12px, 
        rgba(255, 255, 255, 0.07) 24px);
    }

    &[class*=_6]{
        background:#B51F28 repeating-linear-gradient(-45deg, transparent, transparent 12px,
        rgba(255, 255, 255, 0.07) 12px, 
        rgba(255, 255, 255, 0.07) 24px);
    }

    &[class*=_7]{
        background:#91A7DD repeating-linear-gradient(-45deg, transparent, transparent 12px,
        rgba(255, 255, 255, 0.07) 12px, 
        rgba(255, 255, 255, 0.07) 24px);
    }

    .container{
        display: flex;
        align-items: center;
        flex-direction: column;
        .headerImg{
            width: 30.133vw;
            top:0.779vw;
            position: absolute;
        }
        .bgDiv{
            position: absolute;
            width: 100vw;
            .bgTree{
                width: 100%;
                position: absolute;
                display: flex;
                >img{
                    width: 100%;
                }
            }
            .bgTreeBottom{
                width: 100%;
                position: absolute;
                display: flex;
                top:190vw;
                >img{
                    width: 100%;
                }
            }
            .resultTitle{
                width:49.6vw;
                margin: auto;
                position: absolute;
                top:6.67vw;
                left: 0;
                right: 0;
                img{
                    width: 100%;
                }
            }
            .topLight{
                width: 100%;
                position: absolute;
                z-index: 1;
                >img{
                    width: 100%;
                }
            }
            
            .bgSanta{
                width: 55.4%;
                position: absolute;
                z-index: 2;
                left: 20.266vw;
                top: 17.6vw;
                >img{
                    width: 100%;
                }
            }
        }
        .resultDiv{
            position: relative;
            width: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            margin-top: 34vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 3;
            .typeName{
                width:90.4vw;
                z-index: 1;
                display: flex;
                justify-content: center;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: -15vw;
                >img{
                    width: 100%;
                }
            }
            .typeDesc{
                height:16.8vw;
                display: flex;
                justify-content: center;
                position: absolute;
                top: 9vw;
                left: 0;
                right: 0;
                z-index: 1;
                >img{
                    height: 100%;
                }
            }
            .typeDetailsDiv{
                margin-top: 24vw;
                position: relative;
                width: 96.133vw;
                height: 90.266vw;//delete later
                background-image: url('../images/quiz/result_border.png');
                background-size: cover;
                background-repeat: no-repeat;
            }
            .typeStatDiv{
                padding-top: 10vw;
            }
            .typeStat{
                display: flex;
                align-items: center;
                justify-content: center;
                
                width:81.333vw;
                margin: auto;
                border-radius: 2vw;
                background: repeating-linear-gradient(
                    -45deg,
                    #B43C42,
                    #B43C42 10px,
                    #BC333B 10px,
                    #BC333B 20px
                );
                padding: 2vw 0;
                >img{
                    width: 67.467vw;
                }
            }
            .typeDetails{
                width:81.333vw;
                color:white;
                font-size: 0.74rem;
                line-height: 1.5;
                margin: auto;
                margin-top: 10px;
            }
            .typeFooter{
                display: flex;
                width:100%;
                margin: auto;
                //margin-top: 2vw;
                align-items: normal;
                z-index: 2;
                position: relative;
                .typeTags{
                    flex:1;
                    color: white;
                    font-size: 0.65rem;
                    padding-left: 7.4vw;
                    font-family: 'A-OTF Maru Folk Pro';
                    font-style: normal;
                    font-weight: 700;
                    padding-top: 12px;
                    .typeTag{
                        display: inline-block;
                        margin-right: 3vw;
                    }
                }
                .tagImg{
                    flex:1;
                    >img{
                        width: 52.667vw;
                    }
                }
            }
            .middleLight{
                width: 100vw;
                left: -1.9335vw;
                position: absolute;
                z-index: 1;
                top: -15vw;
                pointer-events: none;
                >img{
                    width: 100%;
                }
            }
            .titleBorder{
                border: 1px solid rgba(0, 0, 0, 0.2);
            }


            .productsDiv{
                width: 94.133vw;
                height: 33.333vw;
                display: flex;
                margin: 0 2.933vw;
                position: relative;
                top: 3vw;
                .giftForYouDiv{
                    flex: 1;
                    margin-right: 1.4665vw;
                    // background: repeating-linear-gradient(
                    //     -45deg,
                    //     #286d4c,
                    //     #286d4c 10px,
                    //     #D9D9D9 10px,
                    //     #D9D9D9 20px,
                    //     #B61F28 10px,
                    //     #B61F28 30px,
                    //     #D9D9D9 10px,
                    //     #D9D9D9 40px,
                    // );
                    border-radius: 14px;
                    padding:0.5333vw;
                    .giftBg{
                        position: absolute;
                        width: 52vw;
                        left: -3vw;
                        >img{
                            width: 100%;
                        }
                    }
                    .innerDiv{
                        width: 100%;
                        height: 100%;
                        //background-color: #286648;
                        //box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 14px;
                        display: flex;
                        color:white;
                        font-size: em(10);
                        justify-content: center;
                        padding-top: 1.5vw;
                        >div{
                            flex:50%;
                        }
                        .product{
                            padding: 1vw;
                            margin-top: 3vw;
                        }
                        .productImg{
                            text-align: center;
                            width: 100%;
                            height: 11vw;
                            img{
                                max-width: 100%;
                                height:100%;
                                position: relative;
                            }
                        }
                        .productName{
                            text-align: center;
                            transform: scale(0.8);
                        }
                    }
                    .divName{
                        position: relative;
                        top: -7.5vw;
                        >img{
                            width: 100%;
                        }
                    }
                }
                .giftToSendDiv{
                    flex: 1;
                    margin-left: 1.4665vw;
                    // background: repeating-linear-gradient(
                    //     -45deg,
                    //     #B61F28 ,
                    //     #B61F28 10px,
                    //     #D9D9D9 10px,
                    //     #D9D9D9 20px,
                    //     #286d4c 10px,
                    //     #286d4c 30px,
                    //     #D9D9D9 10px,
                    //     #D9D9D9 40px,
                    // );
                    border-radius: 14px;
                    padding:0.5333vw;
                    .giftBg{
                        position: absolute;
                        width: 52vw;
                        left: 45vw;
                        >img{
                            width: 100%;
                        }
                    }
                    .innerDiv{
                        width: 100%;
                        height: 100%;
                        // background-color: #B51F28;
                        //box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 14px;
                        display: flex;
                        color:white;
                        font-size: em(10);
                        
                        justify-content: center;
                        padding-top: 1.5vw;
                        >div{
                            flex:50%;
                        }
                        .product{
                            padding: 1vw;
                            margin-top: 1vw;
                        }
                        .productImg{
                            text-align: center;
                            width: 100%;
                            height: 11vw;
                            img{
                                max-width: 100%;
                                height:100%;
                                position: relative;
                            }
                        }
                        .productName{
                            text-align: center;
                            transform: scale(0.8);
                        }
                    }
                    .divName{
                        position: relative;
                        top: -7.5vw;
                        >img{
                            width: 100%;
                        }
                    }
                }
            }

            .shareDiv{
                position: absolute;
                display: flex;
                width: 59.882vw;
                height: 22.762vw;
                background-image: url("../images/quiz/green_r_step.svg");
                background-size: cover;
                background-repeat: no-repeat;
                align-self: end;
                top: 176vw;
                align-items: center;
                justify-content: center;
                .btnDiv{
                    flex:1;
                    text-align: center;
                    height: 100%;
                    width: 100%;

                    .btn1{
                        padding-bottom: 3vw;
                        line-height: 150%;
                        &:before{
                            content:"1";
                            left: 2vw;
                            top: 1vw;
                        }
                        span{
                            transform: scale(0.8);
                            >span{
                                white-space: nowrap;
                                background-color: white;
                                border-radius: 5px;
                                padding: 2px;
                                color:#286648;
                            }
                        }
                        &:after{
                            content:"";
                            position:absolute;
                            background-image: url("../images/quiz/result_step_arrow_white.svg");
                            background-repeat: no-repeat;
                            background-size: contain;
                            width:1.86vw;
                            height:5.33vw;
                            left: 19vw;
                            top: 13vw;
                            
                        }
                    }
                    .btn2{
                        padding-bottom: 3vw;
                        &:before{
                            content:"2";
                            left: 21.5vw;
                            top: 1vw;
                        }
                        span{
                            transform: scale(0.8);
                            line-height: 150%;
                            >span{
                                white-space: nowrap;
                                background-color: white;
                                border-radius: 5px;
                                padding: 2px;
                                color:#286648;
                            }
                        }
                        &:after{
                            content:"";
                            position:absolute;
                            background-image: url("../images/quiz/result_step_arrow_white.svg");
                            background-repeat: no-repeat;
                            background-size: contain;
                            width:1.86vw;
                            height:5.33vw;
                            left: 39vw;
                            top: 13.5vw;
                            
                        }
                    }
                    .btn3{
                        padding-bottom: 3vw;
                        span{
                            transform: scale(0.8);
                            line-height: 150%;
                            >span:first-child{
                                white-space: nowrap;
                                background-color: white;
                                border-radius: 5px;
                                padding: 2px;
                                color:#286648;
                            }
                        }
                        &:before{
                            content:"3";
                            left: 41vw;
                            top: 1vw;
                        }
                    }

                    >button{
                        height: 100%;
                        border: none;
                        background-color: transparent;
                        color:white;
                        //font-family: 'GenSenRounded JP';
                        font-size: em(12);
                        font-weight: 500;
                        display: flex;
                        margin: auto;
                        align-items: end;
                        &:before{
                            position: absolute;
                            font-family: 'A-OTF Maru Folk Pro';
                            position: absolute;
                            
                            font-style: normal;
                            font-weight: 700;
                            font-size: em(46);
                            line-height: 150%;
                            padding-bottom: 5vw;
                            /* identical to box height, or 84% */

                            display: flex;
                            align-items: center;

                            color: rgba(255, 255, 255, 0.1);
                        }
                    }
                }
            }

            .fdDiv{
                background-image: url('../images/quiz/bottom_board.png');
                width: 96.133vw;
                height: 77.467vw;
                background-repeat: no-repeat;
                background-size: cover;
                margin-top: 41vw;
                .fdInnerDiv{
                    display: flex;
                    padding: 12vw 3vw 5vw 3vw;
                    color:white;
                    font-style: normal;
                    font-weight: 700;
                    font-size:em(10);
                    .bestFd{
                        flex:1;
                        >div{
                            color:white;
                            &:first-child{
                                font-size: 0.8rem;
                                padding-left: 4vw;
                                margin-bottom: 2vw;
                            }
                        }
                    }
                    .richFd{
                        flex:1;
                        >div{
                            color:white;
                            &:first-child{
                                font-size: 0.8rem;
                                padding-left: 4vw;
                                margin-bottom: 2vw;
                            }
                        }
                    }
                    .fd{
                        &:not(:first-child){
                            color:#212121;
                            display: flex;
                            >div{
                                display: flex;
                                align-items: center;
                                flex: 1;
                                >span{
                                    padding: 3vw 3vw 3vw 5vw;
                                    min-width: 20vw;
                                    font-size: em(10);
                                    font-style: normal;
                                    font-weight: 700;
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                    position: relative;
                                    left: -6vw;
                                }
                            }
                            
                            &[class*=_0]{
                                &:before{
                                    content:"";
                                    background-image:url("../images/quiz/result/0_fd.png");
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    width:18.667vw;
                                    height:16.533vw;
                                    z-index: 1;
                                    left: 3vw;
                                    position: relative;
                                }
                            }
                            &[class*=_1]{
                                &:before{
                                    content:"";
                                    background-image:url("../images/quiz/result/1_fd.png");
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    width:18.667vw;
                                    height:16.533vw;
                                    z-index: 1;
                                }
                            }
                            &[class*=_2]{
                                &:before{
                                    content:"";
                                    background-image:url("../images/quiz/result/2_fd.png");
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    width:18.667vw;
                                    height:16.533vw;
                                    z-index: 1;
                                    left: 4vw;
                                    position: relative;
                                }
                            }
                            &[class*=_3]{
                                &:before{
                                    content:"";
                                    background-image:url("../images/quiz/result/3_fd.png");
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    width:18.667vw;
                                    height:16.533vw;
                                    z-index: 1;
                                    left: 3vw;
                                    position: relative;
                                }
                            }
                            &[class*=_4]{
                                &:before{
                                    content:"";
                                    background-image:url("../images/quiz/result/4_fd.png");
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    width:18.667vw;
                                    height:16.533vw;
                                    z-index: 1;
                                }
                            }
                            &[class*=_5]{
                                &:before{
                                    content:"";
                                    background-image:url("../images/quiz/result/5_fd.png");
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    width:18.667vw;
                                    height:16.533vw;
                                    z-index: 1;
                                }
                            }
                            &[class*=_6]{
                                &:before{
                                    content:"";
                                    background-image:url("../images/quiz/result/6_fd.png");
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    width:18.667vw;
                                    height:16.533vw;
                                    z-index: 1;
                                }
                            }
                            &[class*=_7]{
                                &:before{
                                    content:"";
                                    background-image:url("../images/quiz/result/7_fd.png");
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                    width:18.667vw;
                                    height:16.533vw;
                                    z-index: 1;
                                }
                            }
                            
                        }
                    }
                }
            }

            .resultBtnDiv{
                position: absolute;
                top:280vw;
                display: flex;
                justify-content: center;
                width: 100%;
                padding-bottom: 40px;
                background-color: #941A22;
                .shareBtn{
                    width: 48vw;
                    height: 44px;
                    border: none;
                    background:none;
                    /*background: #F6A559;
                    box-shadow: 0px 3.19608px 7.9902px rgba(0, 0, 0, 0.25), inset 0px 0.79902px 1.59804px rgba(255, 255, 255, 0.5), inset 0px 3.19608px 3.19608px rgba(255, 255, 255, 0.25), inset 0px -3.19608px 1.59804px rgba(216, 39, 60, 0.6);
                    border-radius: 23.9706px;
                    color: #920101;
                    &:before{
                        content: url('../images/share_red.svg');
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                        
                    }*/
                    >img{
                        width: 100%;
                    }
                }

                .backBtn{
                    width: 48vw;
                    height: 44px;
                    border: none;
                    background:none;
                    /*color:#ffff;
                    background: #286D4C;
                    box-shadow: 0px 3.19608px 7.9902px rgba(0, 0, 0, 0.25), inset 0px 0.79902px 1.59804px rgba(255, 255, 255, 0.5), inset 0px 3.19608px 3.19608px rgba(255, 255, 255, 0.25), inset 0px -3.19608px 1.59804px #184F34;
                    border-radius: 23.9706px;
                    
                    &:before{
                        content: url('../images/back_white.svg');
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                    }*/
                    >img{
                        width: 100%;
                    }
                }
            }

            .bottomLight{
                position: absolute;
                width: 100%;
                top: 240vw;
                z-index: 1;
                pointer-events: none;
                display: flex;
                >img{
                    width: 100%;
                }
            }
        }
    }
}