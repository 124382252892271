.login_con_wrap{
    width: 100%;
    position: relative; 
    margin-left: auto; 
    margin-right: auto; 
    text-align: center; 
    margin-top: 23%;
}
.login_popup_con{
    position: relative; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 5px;
}

.login_title{
    width:59.2vw;
}

.slider_center{
    position: fixed;
    z-index:999;
    width: 100%;
    height: 100vh;
    margin: 0;
    //background: #FFFFFF;
}

.popup_bg{
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    width: 100%; 
    height: 100vh; 
    background: rgba(0,0,0,.8); 
    z-index: 999;
}

.login_popup_wrap{
    width: 100%;
    position: relative; 
    margin-left: auto; 
    margin-right: auto; 
    text-align: center; 
    margin-top: 50%;
}

.dismiss_btn_con{
    width: 32px; 
    height: 32px; 
    margin-left: auto; 
    margin-right: auto;
}

.login_popup_con{
    position: relative; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 5px;
}

.login_popup_bg{
    width: 90%; 
    margin-left: auto; 
    margin-right: auto;
}

.login_popup_text_con{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 90%; 
    text-align: center;
    
}

.login_popup_title{
    font-size: 26px; 
    color: #D13740; 
    mix-blend-mode: normal; 
    -webkit-text-stroke: 0.5px #FFFFFF; 
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25); 
    margin-bottom: 2%; 
    margin-top: 6%;
    font-weight: 900;
}

.login_popup_bg{
    height: 100%;
    width: 90%; 
    position: relative;
    margin-left: auto; 
    margin-right: auto;
}
.login_popup_text_con{
    // position: absolute; 
    // top: 0; 
    // left: 0; 
    // right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 90%; 
    // text-align: center;

    // margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// .login_popup_title{
//     font-size: 26px; 
//     color: #D13740; 
//     mix-blend-mode: normal; 
//     -webkit-text-stroke: 0.2px #FFFFFF; 
//     text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25); 
//     margin-bottom: 2%; 
//     margin-top: 6%;
// }

.login_popup_content{
    font-size: 14px; 
    color: #FFFFFF; 
    width: 90%; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 0; 
    margin-bottom: 0;
    line-height:150%;
}


.login_popup_btn{
    width: 80%; 
    padding: 2.5% 0px; 
    font-size: 16px; 
    color: #ffffff; 
    font-weight: 700; 
    background: linear-gradient(180deg, #59A64C 0%, #2E8620 100%); 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.5), inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 2px rgba(14, 100, 43, 0.6); 
    border-radius: 30px; 
    border: 0px solid; 
    margin-top: 5%;
}

/* Galaxy Fold */
@media only screen and (min-device-width: 250px) and (max-device-width: 280px) {

}

/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
}
@media only screen and (min-device-width: 375px) and (min-device-height: 700px) {
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
    .slider_center{
        width: 100%;
    }
}
/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {
    .slider_center{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .login_popup_wrap{
        width: 450px;
        margin-top: 180px;
    }

    .login_con_wrap{
        width: 450px;
        margin-top: 105px;
    }

    

}



/* Media Queries */
@media screen and (max-width: 600px) {

}

