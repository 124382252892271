@import "theme";
.page{
    background: linear-gradient(180deg,#961B1F 0%,#B61F28 50%);
    //min-height: 100vh;
    .container{
        .floor{
            position: relative;
            width: 100vw;
            z-index: 0;
            >img{
                width: 100%;
            }
        }
        .quizDiv{
            z-index: 1;
            position: absolute;
        }
        .bg{
            position: relative;
            width: 100vw;
            img{
                width: 100%;
            }    
        }
        .quizTitle{
            position: absolute;
            width: 99vw;
            left: 1vw;
            top: -55vw;
            img{
                width: 100%;
            }
        }
        .quizDesc{
            position: absolute;
            width: 80vw;
            left: 10vw;
            top: -18vw;
            margin: auto;
            .desc{
                
                //font-family: 'GenSenRounded JP';
                font-style: normal;
                //font-weight: 700;
                font-size: em(18);
                line-height: 23px;
                color: #FFFFFF;
            }
            .startBtnDiv{
                display: flex;
                position: absolute;
                left: 33vw;
                top:18vw;
                width: 48.267vw;
                .startBtn{
                    -webkit-tap-highlight-color: transparent;
                    width: 100%;
                    img{
                        width: 100%;
                    }
                }   
            }
        }
    }
}

/*@font-face {
    font-family: 'A-OTF Maru Folk Pro';
    src: url("../fonts/A-OTF Maru Folk Pro R.otf") format("opentype");
}

@font-face {
    font-family: 'GenSenRounded JP';
    src: url("../fonts/GenSenRounded-B.ttc");
}*/