@import "theme";

.root_div{
    position: fixed;
    z-index: 3;
}

.popup_center{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.popup_blur_bg{
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    width: 100%; 
    height: 100vh; 
    background: rgba(0,0,0,.5); 
    backdrop-filter: blur(12px);
    filter: blur(12px);
    -webkit-filter: blur(12px);
    z-index: 998;
}

.popup_bg{
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    width: 100%; 
    height: 100vh; 
    z-index: 999;
}

.title{
    width: 31.6533vw;
    margin-bottom: 6.4vw;
}

.popup_scroll{
    overflow-y: scroll;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
}

.popup_con{
    margin-top: 15vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight:700;
    font-size: 1.2rem;
    //height: 100%;

    >span{
        margin-bottom: 6.4vw;
    }

    >button{
        border: none;
        background: none;
        width: 47.467vw;
        >img{
            width: 100%;
        }
    }

}

.target_image{
    max-width: 80%;
    max-height: 60vh;
    margin-bottom: 6.4vw;
}