.medal_btn_con{
    position: relative; 
    width: 85.33vw;
    max-width: 320px; 
    height: 55px; 
    top: 0px; 
    left: 0; 
    right: 0; 
    margin-top: 28px; 
    margin-left: auto; 
    margin-right: auto; 
    display: flex; 
    border-radius: 30px; 
    border: 0px solid; 
    padding: 10px 5px; 
    align-items: center;
}

.medal_btn_get{
    background: linear-gradient(180deg, #59A64C 0%, #2E8620 100%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.5), inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 2px #0B4D1A;
}

.medal_btn_text{
    font-size: 16px; 
    color: #ffffff; 
    width: 100%; 
    padding-right: 10px;
}

.medal_btn_done{
    background: linear-gradient(180deg, #AAACAA 0%, #858585 100%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.5), inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 2px #1D1D1D;
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {

    .medal_btn_con{
        margin-top: 40px;
    }

}