.main_center {
  width: 100%;
  margin: 0;
}

.santa_bg_con {
  width: 100%;
  position: absolute;
  top: 550px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #d13748;
}

.video_bg_con {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.photo_bg_con {
  width: 100%;
  position: absolute;
  top: 1380px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.manning_logo_con {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
}

.manning_logo_img {
  width: 30%;
  padding: 5%;
}

.video_con {
  position: absolute;
  width: 100%;
}

.video_deco_con {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
}

.recruit_btn_img {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
}

.video_img_con {
  position: absolute;
  top: 158vw;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.video_img_con img {
  width: 100%;
}

.video_title_con {
  position: absolute;
  top: 12.667vw;
  left: -7vw;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.video_title_con img {
  width: 73%;
}

.deco_gift_left {
  width: 45%;
  position: absolute;
  top: 21vw;
  left: 0px;
}

.deco_gift_right {
  position: absolute;
  top: 60vw;
  right: 0px;
  width: 30%;
}

.video_char_con {
  position: absolute;
  top: 41.33vw;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.video_char_con img {
  width: 80.267%;
}

.deco_light_top {
  position: absolute;
  top: 445px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

/*.video_play_btn_con {
  position: absolute;
  top: 235px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}*/

.video_play_btn_con {
  position: absolute;
  top: 204vw;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}

/*.video_play_btn {
  background: #1a8956;
  width: 22%;
  font-size: 16px;
  color: #ffffff !important;
  border-style: none !important;
  border-radius: 50px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}*/

.video_play_btn {
  width: 32vw;
  font-size: 16px;
  border-style: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video_play_btn_icon {
  width: 25%;
}

.video_play_btn_text {
  width: 75%;
}

.recruit_con {
  position: relative;
  width: 100%;
  margin-bottom: 100px;
}

.recruit_title_con {
  position: absolute;
  width: 100%;
  top: -65px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.recruit_title_con img {
  width: 75%;
}

.recruit_text_bg {
  width: 90%;
  background: #9a292c;
  box-shadow: inset 0 0 10px #2a2a2a;
  border-radius: 50px;
  text-align: center;
  color: #ffffff;
  padding: 60px 0px;
  margin: auto;
}

.recruit_text_con {
  width: 90%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.recruit_text_title {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 4px;
  margin-bottom: 5px;
}

.recruit_text_content {
  line-height:150%;
  font-size: 14px;
  margin: 16px 0 0;
  margin-top: 12px;
}

.recruit_btn_text{
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0;
  margin-left: auto; 
  margin-right: auto; 
  font-size: 16px; 
  font-weight: 900; 
  margin-top: 5.5%;
  color: #690909 !important;
}

.recruit_btn {
  position: absolute;
  width: 74.133vw;
  bottom: 12vw;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #690909;
}

.quiz_con {
  position: relative;
  width: 100%;
  margin-bottom: 186px;
}

.quiz_bg {
  width: 100%;
}

.deco_light_bottom {
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.quiz_con_bg {
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.quiz_title_con {
  position: absolute;
  top: 19vw;
  left: 0px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: left;
  font-size: 37px;
  color: #d13740;
  text-shadow: 5px 5px 5px #000000;
  -webkit-text-stroke: 0.25px #ffffff;
  font-weight: 700;
}

.quiz_title {
  margin: 0;
  margin-left: 14.5%;
  font-weight: 700;
  margin-top: 7.5%;
  line-height: 0;
}
.quiz_title img {
  width: 69%;
}

.quiz_btn_con {
  position: absolute;
  bottom: -22vw;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: left;
  
}

.quiz_btn_img{
  width: 100%;
}

.quiz_btn {
  font-size: 1.375em;
  line-height: 23px;
  font-weight: 600;
  /* background: #1a8956; */
  padding: 6px 14px 6px 9px;
  color: #ffffff;
  background: none;
  border-style: none !important;
  border-radius: 5.33vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14.5%;
  width: 44.26vw;
  height: 14.883vw;
}
.header_short_width {
  width: 15%;
}
.header_long_width {
  width: 70%;
}

.quiz_btn_text {
  width: 80%;
}

.quiz_btn_icon {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz_btn_icon img {
  width: 5.866vw;
}

.photo_con {
  position: relative;
  width: 100%;
}

.bgLandingImgWidth {
  width: 100%;
}

.playBtnWidth {
  /*width: 80%;*/
  width: 100%;
}

.photo_bg {
  width: 95%;
}

.copywrite_con{
  position: relative; 
  width: 100%; 
  text-align: center; 
  font-size: 14px; 
  color: #ffffff; 
  font-weight: 400; 
  margin-top: 12vw;
  opacity: 0.5;
}
.copywrite_text{
  margin-bottom: 10px;
}
.copywrite_policy{
            color: #ffffff;
        }

.photo_title {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.photo_gallery_con {
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.photo_gallery {
  /* display: flex; */
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  /* flex-flow: row wrap; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* margin-bottom: 25px; */
}
.photo_gallery > div {
  margin: 0 auto;
  width:auto!important;

}

.eapps-instagram-feed-posts-inner{
  width:auto;
}

.photo_thumbnail {
  width: 95px;
  height: 95px;
  border-radius: 30px;
  margin-bottom: 5px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.photo_btn_con{
  position: fixed; 
  width: 100%; 
  bottom: 0;
  margin-bottom: 10%;
  margin-top: 30px; 
  text-align: center;
}

.photo_btn{
  width: 80%; 
  padding: 15px 0px; 
  font-size: 16px; 
  color: #ffffff; 
  font-weight: 900; 
  background: linear-gradient(180deg, #59A64C 0%, #2E8620 100%); 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.5), inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 2px rgba(14, 100, 43, 0.6); 
  border-radius: 30px;
  border: 0px solid;
}
.main_center_pc{
  display: none;
}

.desc_con{
  color: white;
  position: absolute;
  top: 110vw;
  width: 80vw;
}

.desc_title{
  font-weight: 600;
}

.desc_content{

}

.reward_btn{
  width: 62.133vw;
  position: absolute;
  top: 140vw;
}


