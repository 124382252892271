.share_center{
    width: 100%;
    //height: 100vh;
    margin: 0;
    background: #961B1F;
}

.header_con{
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-bottom: 20px; 
    display: flex; 
    width: 100%; 
    background: none; 
    z-index: 999;
    align-items: flex-start;
}

.back_btn_con{
    text-align: left; 
    margin-left: 10px;
    margin-top: 12px;
    width: 15%;
}

.header_long_width{
    width: 70%;
}

.header_short_width{
    width: 15%;
}

.back_btn{
    width: 32px; 
    height: 32px;
}

.header_title_img{
    width: 100%;
}

.share_bg{
    width: 100%; 
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
}

.share_con{
    position: absolute; 
    width: 100%; 
    top: 0px; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 54%;
    text-align: center;
}

.share_inner_bg{
    width: 90%; 
    margin-right: auto; 
    margin-left: auto;
}

.share_form_wrap{
    position: absolute; 
    width: 80%; 
    height: 60%; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 9%; 
    overflow-y: overlay;
}

.share_title{
    font-size: 24px; 
    color: #ffffff; 
    font-weight: 700; 
    margin-top: 2%; 
    margin-bottom: 2%;
}

.share_content{
    font-size: 12px; 
    color: #ffffff; 
    margin-top: 0px; 
    margin-bottom: 5%;
}

.share_form_con{
    background: #C7424A; 
    border-radius: 12px; 
    display: flex; 
    align-items: center; 
    padding: 0px 3px; 
    width: 95%; 
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
}

.share_form_input{
    width: 60%; 
    text-align: left; 
    color: #ffffff; 
    font-weight: 700; 
    margin-left: 12px; 
    margin-right: 5px; 
    font-size: 14px;
    overflow-y: hidden;
}

.input_field{
    border: none;
    background-color: #C7424A;
    &::placeholder{
        color:#ffffff;
    }
    &:focus{
        outline: none;
    }
}

.share_btn{
    width: 40%; 
    text-align: center;
    padding: 3% 0px; 
    border-radius: 10px; 
    margin: 2% 0px; 
    font-size: 14px; 
    font-weight: 700;
}

.sh_btn_dis{
    color: #000000; 
    opacity: .58; 
    background: rgba(255, 255, 255, 0.58);
}

.sh_btn_act{
    color: #B00000; 
    opacity: 1; 
    background: #F6A559; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 3px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 4px #EB523D;
}

.sh_btn_log{
    color: #000000; 
    opacity: 1; 
    background: rgb(255, 255, 255); 
}

.share_gift_deco{
    width: 80%; 
    margin-right: auto; 
    margin-left: auto; 
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: -28%;
}

.remain_fd_con{
    margin-top: 10px; 
    text-align: center; 
    margin-left: auto; 
    margin-right: auto; 
    color: #ffffff; 
    font-size: 13px;
}

.number_of_stamp_style{
    position: fixed;
    bottom: 11.466vw;
    left: 0;
    right: 0;
    width: 85%; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 5px; 
    background: #175336; 
    color: #ffffff; 
    font-size: 14px; 
    font-weight: 700; 
    text-align: center; 
    padding: 4% 0; 
    border-radius: 7px;
}

/* Galaxy Fold */
@media only screen and (min-device-width: 250px) and (max-device-width: 280px) {

}

/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 359px) {
    .share_form_wrap{
        height: 58%;
    }

    .share_btn{
        padding: 2% 0;
    }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 374px) {

}
@media only screen and (min-device-width: 375px) and (min-device-height: 700px) {
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
}
/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {
    .share_center{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .header_con{
        width: 450px;
    }

    .share_bg{
        width: 450px;
    }

    .share_con{
        width: 450px;
        margin-top: 248px;
    }

}



/* Media Queries */
@media screen and (max-width: 600px) {

}

