.main_center_pc{
    display: none;
}

.light_pc{
    width: 100%;
    position: absolute;
    top: 0;
    >img{
        width: 100%;
    }
}

.floor_pc{
    width: 100%;
    position: absolute;
    >img{
        width: 100%;
    }
}

.discoball_pc{
    top: 0;
    right: 0;
    width: 25%;
    position: absolute;
    >img{
        width: 100%;
    }
}

        /* Desktop */
        @media screen and (min-width: 450px) {
            body{
                overflow: hidden !important;
                height: auto;
                margin: 0; 
                padding: 0 !important; 
                background-color: #E69D02!important;
            }

            .floor_pc{
                top: 90vw;
            }
            .main_center{
                display: none;
            }

            .main_center_pc{
                width: 100%; 
                margin: 0;
                display: block;
            }

            .landing_pc_bg{
                width: 100%;
            }

            .landing_pc_wrap{
                position: absolute; 
                width: 100%; 
                top: 0; 
                left: 0; 
                right: 0; 
                margin-left: auto; 
                margin-right: auto;
            }

            .landing_pc_logo_con{
                margin-left: auto; 
                margin-right: auto; 
                position: relative; 
                text-align: center;
            }

            .landing_pc_logo_img{
                width: 25%;
                padding: 5%;
            }

            .landing_pc_con{
                margin-left: auto; 
                margin-right: auto; 
                position: relative; 
                text-align: center; 
                margin-top: 15%;
            }

            .landing_pc_inner_bg{
                width: 60%;
            }

            .landing_pc_inner_title{
                position: absolute; 
                top: 0; 
                left: 0; 
                right: 0; 
                margin-left: auto; 
                margin-right: auto; 
                width: 48%; 
                margin-top: -14%;
            }   

            .landing_pc_inner_con{
                position: absolute; 
                top: 0; 
                left: 0; 
                right: 0; 
                margin-left: auto; 
                margin-right: auto; 
                width: 80%; 
                margin-top: 10%;
            }

            .landing_pc_text{
                color: #bc2623; 
                font-size: 2.5vw; 
                font-weight: 700;
            }

            .landing_pc_qr_code{
                position: relative; 
                margin-top: 3%; 
                width: 50%;
            }

            .landing_pc_ppl_l{
                position: absolute; 
                width: 30%; 
                bottom: 0; 
                left: 0; 
                margin-left: 0%; 
                margin-bottom: -5%;
            }

            .landing_pc_ppl_r{
                position: absolute; 
                width: 30%; 
                bottom: 0; 
                right: 0; 
                margin-right: 0%; 
                margin-bottom: -5%;
            }
        }
        

        @media screen and (min-width: 821px) {
            body{
                margin: 0; 
                padding: 0 !important; 
                background-color: #E69D02!important;
            }

            .floor_pc{
                top:40vw;
            }

            .floor_color{
                top:65vw;
                bottom: 0;
                left: 0;
                right: 0;
                position: absolute;
                background-color: #E8A100;
            }

            .main_center{
                display: none;
            }

            .main_center_pc{
                width: 100%; 
                margin: 0;
                display: block;
            }

            .landing_pc_bg{
                width: 100%;
            }

            .landing_pc_wrap{
                position: absolute; 
                width: 80%; 
                top: 0; 
                left: 0; 
                right: 0; 
                margin-left: auto; 
                margin-right: auto;
            }

            .landing_pc_logo_con{
                margin-left: auto; 
                margin-right: auto; 
                position: relative; 
                text-align: center;
            }

            .landing_pc_logo_img{
                width: 15%;
                padding: 5%;
            }

            .landing_pc_con{
                margin-left: auto; 
                margin-right: auto; 
                position: relative; 
                text-align: center; 
                margin-top: 5%;
            }

            .landing_pc_inner_bg{
                width: 35%;
            }

            .landing_pc_inner_title{
                position: absolute; 
                top: 0; 
                left: 0; 
                right: 0; 
                margin-left: auto; 
                margin-right: auto; 
                width: 32%; 
                margin-top: -9%;
            }   

            .landing_pc_inner_con{
                position: absolute; 
                top: 0; 
                left: 0; 
                right: 0; 
                margin-left: auto; 
                margin-right: auto; 
                width: 80%; 
                margin-top: 5%;
            }

            .landing_pc_text{
                color: #bc2623; 
                font-size: 1.2vw; 
                font-weight: 700;
            }

            .landing_pc_qr_code{
                position: relative; 
                margin-top: 3%; 
                width: 28%;
            }

            .landing_pc_ppl_l{
                position: absolute; 
                width: 30%; 
                bottom: 0; 
                left: 0; 
                margin-left: 9%; 
                margin-bottom: -5%;
            }

            .landing_pc_ppl_r{
                position: absolute; 
                width: 30%; 
                bottom: 0; 
                right: 0; 
                margin-right: 10%; 
                margin-bottom: -5%;
            }

        }