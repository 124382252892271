@font-face {
  font-family: A-OTF Maru Folk Pro;
  src: url("../fonts/A-OTF Maru Folk Pro B.otf");
  font-weight: bold;
}

@font-face {
  font-family: A-OTF Maru Folk Pro;
  src: url("../fonts/A-OTF Maru Folk Pro R.otf");
  font-weight: 500;
}

.canvas-con{
  padding: 20px;
  /* width: 350px; */
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  margin: auto;

  /* background-color: rgb( 181, 31, 40 ); */
  background: linear-gradient(180deg, #921B22 5.81%, #921B22 7.33%, #B51F28 13.6%, #B51F28 13.6% );
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.canvas-con > * {
  width: 350px;
}

.top-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.top-row > div {
  background-color: #C7424A;
  box-shadow: 0px 4px 4px #F7B99A,
              inset 0px 4px 3px rgba(0, 0, 0, 0.35);
  border-radius: 13px;

  /* padding: 6px 15px 0px 15px; */
  padding: 5px 12px;
  margin-bottom: 15px;

  color: white;
  font-family: A-OTF Maru Folk Pro;

  white-space: nowrap;
}

.top-row > div:first-child {
  flex-basis: 66%;
  margin-right: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.top-row > div:first-child > span {
  margin-right: 5px;
  /* padding-bottom: 2px; */
}
.top-row > div:first-child > span:first-child {
  /* font-size: 25px; */
  font-size: .925em;
  line-height: 1.5;
}

.top-row > div:first-child .title-img{
  height:6.6667vw;
}

.top-row > div:first-child > span:last-child {
  font-size: 1.8em;
  font-weight: 700;
  line-height: 1.35;

  margin-right: 0;
}

.top-row .avatar-con {
  position: relative;
  align-self: stretch;
  width: 65px;
}
.top-row .avatar-con > img {
  position: absolute;
  width: 100%;
  bottom: -3px;
}


.top-row > div:last-child {
  flex-basis: 33%;
  text-align: center;
  font-size: 1.35em;
  font-weight: 700;
  line-height: 1.8;
}



.inner-con{
  border-radius: 20px;
  overflow: hidden;
  position: relative;

  width: 350px;
  height: 375px;
}

.inner-con > * {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.canvas-cover{
  border-radius: 20px;
  display: none;
  background-color: rgba(0, 0, 0, 0.8);

  z-index: 3;
}

.canvas-cover.active{
  display: block;
}

.canvas-cover > .cover-prompt{
  height: 100%;
  width: 100%;

  color: #C21818;
  font-size: 68px;
  font-weight: 700;
  font-family: A-OTF Maru Folk Pro;

  /* -webkit-text-stroke: white 4px; */
  text-shadow: 3px  3px  2px white,
               0    3px  2px white,
               -3px 3px  2px white,
               3px  0    2px white,
               0    0    2px white,
               -3px 0    2px white,
               3px  -3px 2px white,
               0    -3px 2px white,
               -3px -3px 2px white;

  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-cover > .cover-prompt > span {
  position: absolute;
  transition: all .25s;
  opacity: 0;
  scale: 0;
}

.canvas-cover > .cover-prompt > span.active{
  opacity: 1;
  scale: 1.1;
}

.game-canvas{
  z-index: 2;
  border-radius: 20px;
}

.canvas-bg {
  background-image: url("../images/claw/canvas-bg.png");
  background-size: cover;
}


/* .canvas-cover.ready-state-0 > .cover-prompt > span:nth-child( 0 ){
  display: block;
  opacity: 1;
}
.canvas-cover.ready-state-1 > .cover-prompt > span:nth-child( 1 ){
  display: block;
  opacity: 1;
}
.canvas-cover.ready-state-2 > .cover-prompt > span:nth-child( 2 ){
  display: block;
  opacity: 1;
}
.canvas-cover.ready-state-3 > .cover-prompt > span:nth-child( 3 ){
  display: block;
  opacity: 1;
} */


.btn-row{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  user-select: none;
  -webkit-touch-callout:none
}

/* .btn-row > input {
  box-shadow: 0 6px 16px #00000040;
  border-radius: 50%;

  -webkit-tap-highlight-color: transparent;
}

.btn-row > input:first-child{
  margin-right: 20px;
}

.btn-row > input:last-child{
  margin-left: auto;
} */


.btn-row > button {
  border-radius: 50%;

  width: 85px;
  height: 85px;

  background: none;
  outline: none;
  border: none;

  -webkit-tap-highlight-color: transparent;
}

.btn-row > button.pinkBtn {
  background-color: #FFB8A9;

  box-shadow: 0px 6.45293px 16.1323px rgba(0, 0, 0, 0.25),
              inset 0px 1.61323px 3.22647px rgba(255, 255, 255, 0.5),
              inset 0px 6.45293px 6.45293px rgba(255, 255, 255, 0.25),
              inset 0px -6.45293px 3.22647px rgba(216, 39, 60, 0.6);

  margin-right: 20px;
}

.btn-row > button.orangeBtn {
  background-color: #F6A559;

  box-shadow: 0px 6.45293px 16.1323px rgba(0, 0, 0, 0.25),
              inset 0px 1.61323px 3.22647px rgba(255, 255, 255, 0.5),
              inset 0px 6.45293px 6.45293px rgba(255, 255, 255, 0.25),
              inset 0px -6.45293px 3.22647px rgba(216, 39, 60, 0.6);

  margin-left: auto;
}

.btn-row > button:disabled > img {
  opacity: 0.2;
}





/* popup */
.claw-popup{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: 5;

  display: none;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  

  /* font-family: A-OTF Maru Folk Pro; */
}

.claw-popup-blur-bg{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba( 0, 0, 0, 0.8 );
  backdrop-filter: blur(12px);
  filter: blur(12px);
  -webkit-filter: blur(12px);
  z-index: -1;
}

.claw-popup.active{
  display: flex;
}

.claw-popup button {
  background: linear-gradient(180deg, #59A64C 0%, #2E8620 100%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25),
              inset 0px 1px 2px rgba(255, 255, 255, 0.5),
              inset 0px 4px 4px rgba(255, 255, 255, 0.25),
              inset 0px -4px 2px #0B4D1A;

  outline: none;
  border: none;
  border-radius: 30px;

  width: 200px;
  height: 55px;

  margin-top: 20px;

  color: white;
  font-size: 1.1em;
  font-weight: 700;
}

#target-popup-con .inner-con {
  background: url("../images/claw/target-popup-bg.png");
  background-repeat: no-repeat;
  background-position: center;

  width: 342px;
  height: 291px;

  position: relative;
}

#target-popup-con .inner-con .upper {
  position: absolute;
  top: 5px;
  left: 37.5px;
  right: 37.5px;
  width: 267px;
  height: 42.61px;

  background: #28694A;
  border-radius: 27px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-size: 1.35em;
  font-weight: bold;
}

#target-popup-con .inner-con .lower {
  position: absolute;
  top: 60px;
  left: 4px;
  right: 4px;

  width: 334px;
  height: 226px;

  background: #D13740;
  border-radius: 20px;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  overflow: hidden;
}

#target-popup-con .inner-con .lower > div {
  color: white;
  font-size: 88px;
  font-weight: bold;
  line-height: 1.35;
}

#target-popup-con .inner-con .lower > img {
  position: absolute;
  bottom: 0;
  width: 44.133vw;
  z-index: 1;
}




#result-popup-con .inner-con {
  background: url("../images/claw/result-popup-bg.png");
  background-repeat: no-repeat;
  background-position: center;

  width: 342px;
  height: 338px;

  position: relative;
}

#result-popup-con .inner-con .upper {
  position: absolute;
  top: 5px;
  left: 37.5px;
  right: 37.5px;
  width: 267px;
  height: 42.61px;

  background: #28694A;
  border-radius: 27px;

  color: white;
  font-size: 1.35em;
  font-weight: bold;
}

#result-popup-con .inner-con .upper > .deco {
  background-image: url("../images/claw/light-deco.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  position: absolute;
  top: -20px;
  left: -20px;

  width: 303px;
  height: 82px;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
}

#result-popup-con .inner-con .upper > .deco.active{
  background-image: url("../images/claw/light-deco-active.png");
}

#result-popup-con .inner-con .upper > .deco > span {
  display: none;
}

#result-popup-con .inner-con .upper > .deco > span.active {
  display: block;
}

#result-popup-con .inner-con .lower {
  position: absolute;
  top: 62px;
  left: 4px;
  right: 4px;
  bottom: 4px;

  width: 334px;
  height: 270px;

  padding: 15px 30px 30px 30px;
  box-sizing: border-box;

  background: #28694A;
  border-radius: 20px;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#result-popup-con .inner-con .prompt {
  display: none;
  color: white;
}
#result-popup-con .inner-con .prompt.active {
  display: block;
}

#result-popup-con .inner-con .wrong-prompt {
  font-size: .9em;
}

#result-popup-con .inner-con .missing-prompt {
  margin: auto;
  padding-top: 15px;
}

#result-popup-con .inner-con .lower .inner {

  margin-top: 130px;
  width: 100%;
  height: 120px;
  border-radius: 20px;

  background: rgba( 255, 255, 255, .3 );
  display: none;
  justify-content: center;
  align-items: flex-end;

  padding: 20px 35px;
  box-sizing: border-box;

  color: white;
  text-align: center;
  line-height: 1.2;
}
#result-popup-con .inner-con .lower .inner.active {
  display: flex;
}

#result-popup-con .inner-con .lower .inner img {
  position: absolute;
  max-width: 120px;
  max-height: 120px;
  bottom: 80px;
}

#result-popup-con .inner-con .lower .inner.correct img {
  bottom: 100px;
}

#result-popup-con .inner-con .lower .inner span {
  height: 2em;
}

#result-popup-con button {
  display: none;
}
#result-popup-con button.active {
  display: block;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
