.slider_center{
    width: 100%;
    //height: 100vh;
    margin: 0;
    //background: #FFFFFF;
    position: fixed;
    z-index: 999;
}

.popup_bg{
    position: fixed;
    top: 0;
    bottom:0;
    left: 0;
    right: 0;
    width: 100%;
    //height: 100vh;
    background: rgba(0,0,0,.8);
    z-index: 999;
}

.gift_popup_wrap{
    width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 10%;
}


.gift_mustache{
  position: absolute;
  top: 20%;
  left: 20%;
  right: 20%;

  width: 60%;

  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes floating {
  0% {
    rotate: 15deg;
    translate: 0% 5%;
  }
  25%{
  translate: 0% -10%;
  }
  50% {
    rotate: -5deg;
    translate: 0% 5%;
  }
  75%{
  translate: 0% -10%;
  }
  100% {
    rotate: 15deg;
    translate: 0% 5%;
  }
}


.gift_deco_con{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.gift_popup_con{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
}

.gift_popup_bg{
    width: 75%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 68%;
    margin-left: auto;
    margin-right: auto;
}

.gift_popup_text_con{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    text-align: center;
    margin-bottom: -20%;
}

.gift_popup_title{
    font-size: 24px;
    font-weight: 700;
    color: #222222;
    margin-top: 10px;
    margin-bottom: 10px;
}

.gift_popup_divider{
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}

.gift_popup_content{
    font-size: 16px;
    font-weight: 700;
    color: #222222;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.gift_popup_btn{
    width: 65%;
    padding: 5% 0px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    background: linear-gradient(180deg, #59A64C 0%, #2E8620 100%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.5), inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 2px rgba(14, 100, 43, 0.6);
    border-radius: 30px;
    border: 0px solid;
    margin-top: 3%;
}

/* Galaxy Fold */
@media only screen and (min-device-width: 250px) and (max-device-width: 280px) {

}

@media only screen and (min-device-width: 320px) and (max-device-width: 359px) {
    .gift_popup_text_con{
        margin-bottom: -25%;
    }
}
/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 360px) and (max-device-width: 374px) {
}
@media only screen and (min-device-width: 375px) and (min-device-height: 700px) {
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
    .slider_center{
        width: 100%;
    }
}
/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {
    .slider_center{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .gift_popup_wrap{
        width: 450px;
        margin-top: 100px;
    }
}

/* Media Queries */
@media screen and (max-width: 600px) {

}
