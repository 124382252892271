.coupon_center{
    position: fixed;
    z-index:999;
    width: 100%;
    height: 100vh;
    margin: 0;
    background: #FFFFFF;
}

.popup_bg{
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    width: 100%; 
    height: 100vh; 
    z-index: 999;
}

.popup_blur_bg{
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    width: 100%; 
    height: 100vh; 
    background: rgba(0,0,0,.5); 
    backdrop-filter: blur(12px);
    filter: blur(12px);
    -webkit-filter: blur(12px);
    z-index: 998;
}

.coupon_popup_wrap{
    position: relative; 
    width: 85%; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20%;
}

.coupon_popup_bg_img{
    width: 100%;
}

.coupon_popup_header{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 3%; 
    width: 100%; 
    display: flex; 
    align-items: center;
}

.coupon_popup_logo{
    width: 55%;
}

.coupon_popup_dismiss{
    width: 32px;
    height: 32px;
}

.coupon_popup_con{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20%; 
    width: 100%;
}

.coupon_popup_title{
    position: relative; 
    width: 100%; 
    margin-left: auto; 
    margin-right: auto; 
    display: flex; 
    justify-content: center; 
    align-items: baseline;
}

.coupon_popup_title_l{
    font-size: 34px; 
    font-weight: 700; 
    color: #ffffff; 
    margin: 0 5px;
}   

.coupon_popup_qr_code{
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.coupon_popup_title_s{
    font-size: 18px; 
    font-weight: 700; 
    color: #ffffff; 
    margin: 0 5px;
}

.coupon_popup_content{
    display: flex; 
    font-weight: 700;
    flex-direction: column;
    position: relative; 
    margin-top: 3%; 
    width: 85%; 
    height: 165px; 
    background: #FFFFFF; 
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.35); 
    border-radius: 10px; 
    padding: 3%; 
    font-size: 12px; 
    font-weight: 600; 
    color: rgba(0,0,0,.7); 
    text-align: left; 
    overflow-y: overlay;
    margin-left: auto;
    margin-right: auto;
    overscroll-behavior: none;
    font-family: sans-serif;
    ul{
        list-style-type: none;
        text-indent: -20px;
        padding-left: 25px;
        li:before{
            content: "—";
            margin-right: 8px;
        }
    }
}


.coupon_popup_qr_img{
    width: 55%;
}

.coupon_popup_lower_wrap{
    position: absolute; 
    bottom: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-bottom: 10%; 
    width: 100%;
}

.coupon_popup_lower_con{
    position: relative; 
    width: 95%; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 3%;
}

.coupon_popup_divider{
    width: 100%; 
    margin-bottom: 5px;
}

.coupon_popup_inner_con{
    position: relative; 
    width: 95%; 
    display: flex; 
    margin-top: 2%;
    align-items: center;
    margin-left: auto;
    margin-right: auto
}

.coupon_popup_qty_con{
    display: flex; 
    width: 50%; 
    margin: 0 2%; 
    align-items: center;
}

.popup_btn_con{
    width: 50%; 
}

.coupon_popup_qty_icon_con{
    width: 25%; 
    display: flex; 
    align-items: center;
}

.coupon_popup_qty_icon{
    width: 100%;
}

.coupon_popup_qty_text_con{
    width: 50%;
    text-align: center;
}

.coupon_popup_qty_text{
    font-size: 22px; 
    color: #ffffff;
}

.coupon_inner_btn{
    background: #ffffff; 
    position: relative; 
    margin-left: auto; 
    margin-right: auto; 
    border: 0px solid; 
    border-radius: 50px; 
    width: 95%; 
    font-size: 12px; 
    font-weight: 600; 
    color: #000000; 
    padding: 7%;
}

.coupon_inner_btn_wrap{
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.coupon_claim_icon{
    width: 55%; 
    margin: 0 5px;
}

.coupon_claim_redeem_icon{
    width: 30%; 
    margin: 0 5px;
}

.coupon_popup_confirm_left_con{
    width: 50%; 
    margin: 0 2%; 
    text-align: left;
}

.coupon_popup_confirm_right_con{
    width: 50%; 
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.coupon_popup_confirm_title{
    width: 100%; 
    font-size: 16px; 
    color: #ffffff; 
    font-weight: 600;
}   

.coupon_popup_confirm_text{
    width: 100%; 
    font-size: 12px; 
    color: #ffffff; 
    font-weight: 600; 
    opacity: .5;
}

.coupon_popup_confirm_btn{

}

.coupon_popup_confirm_btn_outline{
    width: 50%; 
    border-radius: 11px; 
    border: 1px solid #ffffff; 
    padding: 10% 0; 
    font-size: 16px; 
    font-weight: 600;
    background: none; 
    color: #ffffff; 
}

.coupon_popup_confirm_btn_fill{
    width: 50%; 
    border-radius: 11px; 
    border: 1px solid #ffffff; 
    padding: 10% 0; 
    font-size: 16px; 
    font-weight: 600;
    background: #ffffff; 
    color: #000000; 
    margin-left: 5px;
}

.coupon_popup_done_btn{
    width: 100%; 
    margin-left: auto; 
    margin-right: auto; 
    border: 0px solid #ffffff; 
    border-radius: 11px; 
    background: #ffffff; 
    color: #000000; 
    padding: 5% 0; 
    font-size: 16px; 
    font-weight: 600;
}
.coupon_header_short_width{
    width:15%;
}
.coupon_header_long_width{
    width:70%;
    text-align: center;
}

.number_of_stamp_style{
    width: 85%; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 5px; 
    background: #175336; 
    color: #ffffff; 
    font-size: 14px; 
    font-weight: 700; 
    text-align: center; 
    padding: 4% 0; 
    border-radius: 7px;
}


/* Galaxy Fold */
@media only screen and (min-device-width: 250px) and (max-device-width: 280px) {
    .coupon_popup_content{
        height: 125px;
        font-size: 11px;
    }

    .coupon_claim_icon{
        width: 30%;
    }

    .coupon_popup_confirm_title{
        font-size: 14px;
    }

    .coupon_popup_confirm_text{
        font-size: 10px;
    }
}

/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
}
@media only screen and (min-device-width: 375px) and (min-device-height: 700px) {
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
    .coupon_center{
        width: 100%;
    }
}
/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {
    .coupon_center{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .popup_bg{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .popup_blur_bg{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .coupon_popup_wrap{
        margin-top: 180px;
    }
}



/* Media Queries */
@media screen and (max-width: 600px) {

}

