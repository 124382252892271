.header{
  display: flex;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  padding-bottom: 8px; //for overlay
  background:#BA2F37 repeating-linear-gradient(-45deg, transparent, transparent 12px,
    rgba(255, 255, 255, 0.07) 12px, 
    rgba(255, 255, 255, 0.07) 24px);
  .left{
    flex:1.5;
  }
  .middle{
    flex:7;
    display: flex;
    justify-content: center;
  }
  .right{
    flex:1.5
  }

  .backBtn{
    background-color: white;
    border-radius: 30px;
    width: 32px;
    height: 32px;
    margin: 17px 12px 18px 16px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .title{
    width: 70vw;
    display: flex;
    justify-content: center;
    //position: absolute;
    img{
      width: 100%;
    }
  }
  .bottomOverlay{
    position: absolute;
    bottom:0;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(203,97,104,0.79),#921B22);
    height: 11px;
    width: 100%;
  }
}