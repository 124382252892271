.left_cane_con{
    width: 44.13vw;
    position: relative;
    left: 18vw;
}
.candy_cane_con{
    position: absolute; 
    width: 100%; 
    top: 70vw;
    left: -8%;
    right: 0;
}
.right_cane_con{
    width: 44.13vw;
    position: absolute; 
    bottom: 0; 
    top: 36vw;
    left: 54vw;
    right: 0; 
}

.custom_sticker_con{
    width: 50%; 
    margin-left: 16%; 
    position: absolute; 
    top: 0; 
    left: 0; 
    margin-top: -36%;
}

.xmas_sticker_con{
    width: 50%; 
    margin-right: 10%; 
    position: absolute; 
    top: -1%; 
    right: -10%; 
    margin-top: 2%;
}

.custom_sticker_portal{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-top: 14%; 
    margin-left: -19%;
}
.cane_sticker_title{
    font-weight: 700;
    text-align: center; 
    color: #ffffff; 
    font-size: 24px;
}

.custom_portal_btn{
    position: relative; 
    width: 102px; 
    height: 38px; 
    text-align: center; 
    background: linear-gradient(180deg, #59A64C 0%, #2E8620 100%); 
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.5), inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 2px #0B4D1A;
    border-radius: 30px; 
    border: 0px solid; 
    padding: 10px 5px; 
    display: flex; 
    align-items: center; 
    margin-top: 8px; 
    margin-left: auto; 
    margin-right: auto;
}

.cane_btn_text{
    width: 80%; 
    color: #ffffff; 
    font-size: 14px;
    font-weight: 700;
}

.cane_btn_icon{
    width: 20%;
    display: flex; 
    align-items: center;
}   
.xmas_portal_btn{
    position: relative;
     width: 102px; 
     height: 38px; 
     text-align: center; 
     background: linear-gradient(180deg, #59A64C 0%, #2E8620 100%); 
     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(255, 255, 255, 0.5), inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -4px 2px #0B4D1A;
     border-radius: 30px; 
     border: 0px solid; 
     padding: 10px 5px; 
     display: flex; 
     align-items: center; 
     margin-top: 8px; 
     margin-left: auto; 
     margin-right: auto;
}

.cane_btn_img{
    width: 75%;
}
.xmas_sticker_portal{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-top: 50%; 
    margin-left: 55%;
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
    .xmas_sticker_portal{
        margin-left: 50%;
    }
}

/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 360px) and (max-device-width: 374px) {
    .xmas_sticker_portal{
        margin-left: 60%;
    }
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {
    .candy_cane_con{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

}