.coupon_center{
    position: fixed;
    z-index:999;
    //width: 100%;
    //height: 100vh;
    top:0;
    bottom: 0;
    margin: 0;
    //background: #FFFFFF;
}

.popup_bg{
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    //width: 100%; 
    bottom:0;
    //height: 100vh; 
    z-index: 999;
}

.popup_blur_bg{
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0;
    //width: 100%; 
    //height: 100vh; 
    background: rgba(0,0,0,.5); 
    backdrop-filter: blur(12px);
    filter: blur(12px);
    -webkit-filter: blur(12px);
    z-index: 998;
}

.popup_scroll{
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.popup_policy_con{
    width: 90%; 
    margin-left: auto; 
    margin-right: auto; 
    position: relative; 
    margin-top: 5%;
    margin-bottom: 5%;
}

.popup_policy_title{
    text-align: center; 
    font-size: 16px; 
    font-weight: 700; 
    color: #ffffff; 
    margin-top: 25px;
}

.popup_policy_inner_con{
    margin-top: 25px;
}

.popup_policy_inner_title{
    text-align: left; 
    font-size: 14px; 
    color: #ffffff; 
    font-weight: 700;
}   

.popup_policy_inner_text{
    text-align: left; 
    font-size: 14px; 
    color: #ffffff; 
    font-weight: 400; 
    margin-top: 10px;
    line-height: 1.5;
}


/* Galaxy Fold */
@media only screen and (min-device-width: 250px) and (max-device-width: 280px) {
}

/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
}
@media only screen and (min-device-width: 375px) and (min-device-height: 700px) {
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
    .coupon_center{
        width: 100%;
    }
}
/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {
    .coupon_center{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .popup_bg{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .popup_blur_bg{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }


/* Media Queries */
@media screen and (max-width: 600px) {

}}