.selfie_center{
  width: 100%;
  /* height: 100vh; */
  margin: 0;
  background: #961B1F;
}

.header_con{
  position: fixed; 
  top: 0; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  margin-bottom: 20px; 
  display: flex; 
  width: 100%; 
  background: none; 
  z-index: 999;
  align-items: flex-start;
}

.back_btn_con{
  text-align: left; 
  margin-left: 10px;
  margin-top: 12px;
  width: 15%;
}
.header_short_width{
  width: 15%;
}
.header_long_width{
  width: 70%;
}

.back_btn{
  width: 32px; 
  height: 32px;
}

.header_title_img{
  width: 100%;
}

.selfie_bg{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.selfie_title_con{
  position: absolute; 
  text-align: center;
  width: 100%; 
  top: 0; 
  left: 0; 
  right: 0;
  margin-left: auto;
  margin-right: auto; 
  margin-top: 90px;
  z-index: 996;
}   

.selfie_title{
  width: 55%;
}

.selfie_con{
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0;
  margin-left: auto;
  margin-right: auto; 
  margin-top: 56%; 
  z-index: 99;
  text-align: center;
}

.selfie_img{
  width: 57.467vw;
  border-radius: 16px; 
  /* border: 5px solid #E8483E; */
}

.ig_con{
  width: 100%;
  position: absolute;
}

.ig_deco{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto; 
  z-index: 995;
}

.ig_portal_btn{
  position: absolute; 
  border: none;
  width: 150px; 
  height: 150px; 
  top: 0; 
  right: 0;
  margin-left: auto;
  margin-right: auto; 
  margin-top: 70%; 
  background: none;
  z-index: 996;
}


/* Galaxy Fold */
@media only screen and (min-device-width: 250px) and (max-device-width: 280px) {
  .selfie_title_con{
      margin-top: 70px;
  }
}

/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
}
@media only screen and (min-device-width: 375px) and (min-device-height: 700px) {
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
  .selfie_center{
      width: 100%;
  }
}
/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
}

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {
  .selfie_center{
      width: 450px;
      margin-left: auto;
      margin-right: auto;
  }

  .selfie_bg{
      width: 450px;
  }

  .selfie_con{
      width: 450px;
      margin-top: 245px;
  }

  .selfie_title_con{
      width: 450px;
  }

  .header_con{
      width: 450px;
  }

  .ig_con{
      width: 450px;
  }

}

    /* Media Queries */
    @media screen and (max-width: 600px) {

    }

