.tutor_img{
    width: 75%;
}
.tutor_con{
    position: absolute; 
    width: 100%; 
    margin-top: 350px;
}

.tutor_img_con{
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: -245px; 
    width: 100%; 
    z-index: 990;
    pointer-events: none;
}
/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
    .tutor_img_con{
        margin-top: -68%;
    }
    .tutor_con{
        margin-top: 95%;
    }
}

/* Galaxy Fold */
@media only screen and (min-device-width: 250px) and (max-device-width: 280px) {
    .tutor_img_con{
        margin-top: -68%;
    }
    .tutor_con{
        margin-top: 300px;
    }
}   

/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {

    .tutor_img_con{
        margin-top: -300px;
    }

    .tutor_con{
        width: 450px;
        margin-top: 450px;
    }

}