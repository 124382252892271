.sticker_center{
    width: 100%;
    /* height: 100vh; */
    margin: 0;
    background: #B51F28;
}

.bgDiv{
    height:100%;
    overflow: hidden;
    position: relative;
}

.sticker_bg{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
}

.sticker_bg_deco{
    position: absolute; 
    width: 100%; 
    top: 30vw; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
}
.candy_cane_con{
    position: absolute; 
    width: 100%; 
    bottom: 0; 
    left: 0;
    right: 0;
}

/* Galaxy Fold */
@media only screen and (min-device-width: 250px) and (max-device-width: 280px) {
}
@media only screen and (min-device-width: 320px) and (max-device-width: 359px) {

}

@media only screen and (min-device-width: 375px) and (min-device-height: 700px) {
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
}


/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 479px) {
    .sticker_center{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .header_con{
        width: 450px;
    }

    .sticker_bg{
        width: 450px;
    }

    .sticker_bg_deco{
        width: 450px;
    }

    .candy_cane_con{
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

}




/* Media Queries */
@media screen and (max-width: 600px) {

}
