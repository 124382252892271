a:hover,
a:active,
a:focus,
a:visited {
  color: inherit;
}
button {
  font-family: projectFont, sans-serif;
  font-weight: 300;
}
.App {
  text-align: center;
  font-family: projectFont, sans-serif;
  font-weight: 300;
}
body {
  font-family: projectFont, sans-serif;
  font-weight: 300;
  line-height: 1.2;
  overscroll-behavior: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  margin: 0; 
  padding: 0 !important; 
  background-color: #B51F28;
}

a {
  text-decoration: none; 
  cursor: pointer;
}

.deep_color_bg{
  background-color: #941A22;
}

.green_color_bg{
  background-color: #1E553B;
}